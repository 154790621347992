import { WASMWorkletProcessor } from '../../audioUtils';
// @ts-ignore
import Module from './Processor.wasmmodule.js';

registerProcessor(
  'ResonatorProcessor',
  class ResonatorProcessor extends WASMWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'frequency',
          defaultValue: 220,
          minValue: 11.25,
          maxValue: 14080,
          automationRate: 'k-rate',
        },
        {
          name: 'detune',
          defaultValue: 0,
          automationRate: 'k-rate',
        },
        {
          name: 'structure',
          defaultValue: 0.25,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'brightness',
          defaultValue: 0.5,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'damping',
          defaultValue: 0.3,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'position',
          defaultValue: 0.999,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
      ];
    }

    constructor() {
      super(
        Module,
        (
          processor: any,
          inputPointer: number,
          outputPointer: number,
          {
            frequency: [frequency],
            detune: [detune],
            structure: [structure],
            brightness: [brightness],
            damping: [damping],
            position: [position],
          }: { [key: string]: Float32Array },
        ): void => {
          processor.process(
            inputPointer,
            outputPointer,
            frequency,
            detune,
            structure,
            brightness,
            damping,
            position,
            sampleRate,
          );
        },
      );
    }
  },
);
